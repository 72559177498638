import { useDayjs } from '#dayjs'

import {
    Claim,
    ClaimsList,
    Contractor,
    ContractorBalance,
    ContractorContact,
    ContractorDocuments,
    DocError,
    DocumentList,
    KeyValueObject,
    Location,
    Meta,
    Terminal
} from "~~/types/types"
import {defineStore} from 'pinia';

export const useContractorStore = defineStore('contractors', () => {
    const dayjs = useDayjs()
    const locations = ref<Location[]>([])
    const terminals = ref<Terminal[]>([])
    const hasEDO = ref(false)
    const contact = ref<ContractorContact>()
    const accsessLabel = [
        `
        Получив доступ к контрагенту, вы сможете:<br>
        1. Просматривать все перевозки, где данный контрагент является отправителем, получателем или плательщиком.<br>
        2. Видеть баланс контрагента.<br> 3. Запрашивать оригиналы документов 
        `,
        'После отключения от этой функции процедуру получения полного доступа придётся пройти заново'
    ]
    let contractor = ref<Contractor>(null)
    const balance = reactive<ContractorBalance>({
        sum: '0',
        updatedDate: ''
    })
    const editableCard = ref(false)

    const contractorBalance = computed((): ContractorBalance => {
        const sum = parseFloat(balance.sum || '0').toLocaleString('ru-RU', { minimumFractionDigits: 2 }).replace(',00', '')
        return {
            sum: `${sum} ₽`,
            updatedDate: dayjs(balance.updatedDate).isValid() ? balance.updatedDate : ''
        }
    })

    const contractorGuid = computed((): string => {
        if (contractor.value) {
            return contractor.value.guid
        }
        return ''
    })

    const contacts = computed((): ContractorContact[] => {
        return contractor.value?.contacts || []
    })

    const accessHint = computed((): string => {
        const access = contractor.value?.access ? 1 : 0
        return accsessLabel[access]
    })

    const hasContractorFullAccess = computed((): boolean => {
        return !!contractor.value?.access
    })

    const contractorBalanceTopUp = computed((): boolean => {
        return !!contractor.value?.balanceTopUp
    })

    const hasContractorEDO = computed((): boolean => {
        return !!contractor.value?.edw?.access
    })

    const edoDescription = computed((): string => {
        return contractor.value?.edw?.description || ''
    })

    const documents = computed((): ContractorDocuments[] | DocError => {
        return contractor.value?.documents?.data || []
    })

    const isCorporationContractor = computed((): boolean => {
        return contractor.value?.entity === 'corporation'
    })

    const claims = computed((): Claim[] => {
        return contractor.value?.claims?.list || []
    })

    const contractorErrors = computed((): KeyValueObject => {
        return {
            documentsError: (documents as DocError)?.error?.message || ''
        }
    })

    const docsMeta = computed((): Meta => {
        return (
            contractor.value?.documents?.meta || { limit: 10, page: 1, totalPage: 1 }
        )
    })

    const claimsMeta = computed((): Meta => {
        return contractor.value?.claims?.meta || { limit: 10, page: 1, totalPage: 1 }
    })

    async function setContractor(cntr: Contractor): Promise<void> {
        await setContractorBalance({
            sum: cntr.balance,
            updatedDate: cntr.balanceDateUpdated
        })
        contractor.value = cntr
    }

    function setContractorClaims(claims: ClaimsList): void {
        if (contractor) {
            contractor.value.claims = claims
        }
    }

    function setClaimsMeta(claimsMeta: Meta): void {
        if (contractor) {
            contractor.value.claims.meta = claimsMeta
        }
    }

    function setContractorDocuments(docs: DocumentList): void {
        if (contractor) {
            contractor.value.documents = docs
        }
    }

    function setDocsMeta(docsMeta: Meta): void {
        if (contractor) {
            contractor.value.documents.meta = docsMeta
        }
    }

    function setEditableCard(editable: boolean): void {
        editableCard.value = editable
    }

    function setDefaultContact(defContact: Contractor['defaultContact']): void {
        if (contractor) {
            contractor.value.defaultContact = defContact
        }
    }

    function setContacts(contacts: ContractorContact[]): void {
        if (contractor) {
            contractor.value.contacts = contacts
        }
    }

    function setFavorite(fav: boolean): void {
        if (contractor) {
            contractor.value.favorite = fav
        }
    }

    function setContractorName(name: string): void {
        if (contractor) {
            contractor.value.name = name
        }
    }

    function setContact(c: ContractorContact) {
        contact.value = c
    }

    function setContractorBalance(balance: ContractorBalance): void {
        balance = balance
    }

    function setTerminals(t: Terminal[]) {
        terminals.value = t
    }

    function setLocations(l: Location[]) {
        locations.value = l
    }

    return {
        contact,
        setContact,
        accessHint,
        accsessLabel,
        balance,
        claims,
        claimsMeta,
        contacts,
        contractor,
        contractorBalance,
        contractorErrors,
        hasContractorFullAccess,
        contractorBalanceTopUp,
        contractorGuid,
        docsMeta,
        documents,
        editableCard,
        edoDescription,
        hasContractorEDO,
        hasEDO,
        isCorporationContractor,
        locations,
        terminals,
        setClaimsMeta,
        setContacts,
        setContractor,
        setContractorBalance,
        setContractorDocuments,
        setContractorName,
        setDefaultContact,
        setDocsMeta,
        setEditableCard,
        setFavorite,
        setContractorClaims,
        setTerminals,
        setLocations,
    }

})
